function _extends() {
    return (_extends = Object.assign || function(target) {
        for(var i = 1; i < arguments.length; i++){
            var source = arguments[i];
            for(var key in source)Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
        }
        return target;
    }).apply(this, arguments);
}
import "./polyfill";
export function createPromiseCache(resolve) {
    function fn(input) {
        let promise = cache.get(input);
        return promise || ((promise = createPromiseWithState()).resolve(resolve(input)), cache.set(input, promise)), promise;
    }
    function clear(input) {
        cache.delete(input);
    }
    let cache = new Map();
    return Object.assign(fn, {
        async: Object.assign(function(input) {
            let promise = fn(input);
            return "fulfilled" === promise.state ? promise.value : null;
        }, {
            clear
        }),
        clear
    });
}
export function createPromiseCacheWithResolver(resolver) {
    function fn(input) {
        let promise = cache.get(input);
        return promise || (resolver(input, promise = createPromiseWithState()), cache.set(input, promise)), promise;
    }
    let cache = new Map();
    return Object.assign(fn, {
        get async () {
            return (input)=>{
                let promise = fn(input);
                return "fulfilled" === promise.state ? promise.value : null;
            };
        }
    });
}
export function createPromiseWithState() {
    let resolvers = Promise.withResolvers(), ret = _extends({}, resolvers, {
        state: "pending"
    });
    return resolvers.promise.then((value)=>{
        ret.state = "fulfilled", ret.value = value;
    }, (error)=>{
        ret.state = "rejected", ret.error = error;
    }), ret;
}
