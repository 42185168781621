var // @ts-ignore
_Symbol, // @ts-ignore
_Symbol1;
/* eslint-disable prefer-spread */ /* eslint-disable @typescript-eslint/no-explicit-any */ /* eslint-disable @typescript-eslint/ban-ts-comment */ /// <reference lib="es2019.array" />
/// <reference lib="es2019.object" />
/// <reference lib="es2022.array" />
/// <reference lib="es2023.array" />
/// <reference lib="dom" />
/// <reference lib="dom.iterable" />
/// <reference lib="dom.asynciterable" />
function polyfill(target, name, value) {
    name in target || Object.defineProperty(target, name, {
        configurable: !0,
        value
    });
}
let copyarray = (arr)=>Array.prototype.slice.call(arr);
polyfill(Array.prototype, "flat", function flat(depth) {
    // @ts-ignore
    return void 0 === depth && (depth = 1), depth ? // @ts-ignore
    Array.prototype.reduce.call(this, (acc, cur)=>(Array.isArray(cur) ? acc.push.apply(acc, flat.call(cur, depth - 1)) : acc.push(cur), acc)) : Array.prototype.slice.call(this);
}), polyfill(Array.prototype, "flatMap", function() {
    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    return Array.prototype.map.apply(this, arguments).flat();
}), polyfill(Array.prototype, "at", function(index) {
    let len = this.length, idx = +index, k = idx >= 0 ? idx : len + idx;
    if (!(k < 0) && !(k >= len)) return this[k];
}), polyfill(Array.prototype, "findLast", function(predicate, thisArg) {
    void 0 === thisArg && (thisArg = this);
    let arr = copyarray(this), len = arr.length >>> 0;
    for(let i = len - 1; i >= 0; i--)if (predicate.call(thisArg, arr[i], i, arr)) return arr[i];
}), polyfill(Array.prototype, "findLastIndex", function(predicate, thisArg) {
    void 0 === thisArg && (thisArg = this);
    let arr = copyarray(this), len = arr.length >>> 0;
    for(let i = len - 1; i >= 0; i--)if (predicate.call(thisArg, arr[i], i, arr)) return i;
    return -1;
}), polyfill(Array.prototype, "with", function(index, value) {
    let copied = copyarray(this);
    return copied[index] = value, copied;
}), polyfill(Array.prototype, "toSorted", function(compareFn) {
    return copyarray(this).sort(compareFn);
}), polyfill(Array.prototype, "toSpliced", function(start, deleteCount) {
    return copyarray(this).splice(start, deleteCount);
}), polyfill(Array.prototype, "toReversed", function() {
    return copyarray(this).reverse();
}), polyfill(String.prototype, "at", function(index) {
    let len = this.length, idx = +index, k = idx >= 0 ? idx : len + idx;
    if (!(k < 0) && !(k >= len)) return this[k];
});
let emptyStringRegex = /(?:)/g;
if (polyfill(String.prototype, "replaceAll", function(searchValue, replaceValue) {
    if ("string" != typeof this) throw TypeError("Expected a string");
    if (searchValue instanceof RegExp) {
        let { global: globalFlag } = searchValue;
        if (!globalFlag) throw TypeError("`String.prototype.replaceAll` ponyfill called with a non-global RegExp argument");
        return this.replace(searchValue, replaceValue);
    }
    if ("" === searchValue) {
        let value = this.replace(emptyStringRegex, replaceValue);
        return emptyStringRegex.lastIndex = 0, value;
    }
    let stringToFind = "string" != typeof searchValue ? searchValue + "" : searchValue;
    return this.replace(RegExp(stringToFind.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&").replace(/-/g, "\\x2d"), "g"), replaceValue);
}), polyfill(Object, "fromEntries", function(iterable) {
    return [
        ...iterable
    ].reduce((obj, param)=>{
        let [key, val] = param;
        return obj[key] = val, obj;
    }, {});
}), polyfill(URL.prototype, "toJSON", function() {
    return this.toString();
}), polyfill(URL, "canParse", (url)=>{
    try {
        return new URL(url), !0;
    } catch (e) {
        return !1;
    }
}), polyfill(Object, "groupBy", (items, callbackfn)=>{
    let obj = Object.create(null);
    for(let i = 0; i < items.length; i++){
        let item = items[i], key = callbackfn(item, i);
        obj[key] = [
            ...obj[key] || [],
            item
        ];
    }
    return obj;
}), polyfill(Promise, "withResolvers", function() {
    let result = Object.create(null);
    return result.promise = new Promise((resolve, reject)=>{
        result.resolve = resolve, result.reject = reject;
    }), result;
}), null != (_Symbol = Symbol).dispose || (_Symbol.dispose = Symbol("Symbol.dispose")), null != (_Symbol1 = Symbol).asyncDispose || (_Symbol1.asyncDispose = Symbol("Symbol.asyncDispose")), "undefined" != typeof window) {
    function roundRect(x, y, w, h, radii_) {
        var value;
        let upperLeft, upperRight, lowerRight, lowerLeft;
        function toDOMPointInit(value) {
            let { x, y, z, w } = value;
            return {
                x,
                y,
                z,
                w
            };
        }
        function toCornerPoint(value) {
            var _value_x, _value_y;
            let asNumber = +value;
            return Number.isFinite(asNumber) ? {
                x: asNumber,
                y: asNumber
            } : Object(value) === value ? {
                x: +(null != (_value_x = value.x) ? _value_x : 0),
                y: +(null != (_value_y = value.y) ? _value_y : 0)
            } : {
                x: NaN,
                y: NaN
            };
        }
        if (![
            x,
            y,
            w,
            h
        ].every((input)=>Number.isFinite(input))) return;
        let radii = // https://webidl.spec.whatwg.org/#es-union
        // with 'optional (unrestricted double or DOMPointInit
        //   or sequence<(unrestricted double or DOMPointInit)>) radii = 0'
        null == (value = radii_) ? [
            0
        ] : "function" == typeof value ? [
            NaN
        ] : "object" == typeof value ? Symbol.iterator in value && "function" == typeof value[Symbol.iterator] ? [
            ...value
        ].map((elem)=>{
            // https://webidl.spec.whatwg.org/#es-union
            // with '(unrestricted double or DOMPointInit)'
            let elemType = typeof elem;
            return "undefined" === elemType || null === elem ? 0 : "function" === elemType ? NaN : "object" === elemType ? toDOMPointInit(elem) : +elem;
        }) : [
            toDOMPointInit(value)
        ] : [
            +value
        ];
        if (4 === radii.length) upperLeft = toCornerPoint(radii[0]), upperRight = toCornerPoint(radii[1]), lowerRight = toCornerPoint(radii[2]), lowerLeft = toCornerPoint(radii[3]);
        else if (3 === radii.length) upperLeft = toCornerPoint(radii[0]), upperRight = toCornerPoint(radii[1]), lowerLeft = toCornerPoint(radii[1]), lowerRight = toCornerPoint(radii[2]);
        else if (2 === radii.length) upperLeft = toCornerPoint(radii[0]), lowerRight = toCornerPoint(radii[0]), upperRight = toCornerPoint(radii[1]), lowerLeft = toCornerPoint(radii[1]);
        else if (1 === radii.length) upperLeft = toCornerPoint(radii[0]), upperRight = toCornerPoint(radii[0]), lowerRight = toCornerPoint(radii[0]), lowerLeft = toCornerPoint(radii[0]);
        else throw RangeError(getErrorMessageHeader(this) + " " + radii.length + " is not a valid size for radii sequence.");
        let corners = [
            upperLeft,
            upperRight,
            lowerRight,
            lowerLeft
        ], negativeCorner = corners.find((param)=>{
            let { x, y } = param;
            return x < 0 || y < 0;
        });
        if (negativeCorner && (null == negativeCorner ? void 0 : negativeCorner.x) < 0 ? negativeCorner.x : null == negativeCorner || negativeCorner.y, !corners.some((param)=>{
            let { x, y } = param;
            return !Number.isFinite(x) || !Number.isFinite(y);
        })) {
            if (negativeCorner) throw RangeError(getErrorMessageHeader(this) + " Radius value " + negativeCorner + " is negative.");
            !function(corners) {
                let [upperLeft, upperRight, lowerRight, lowerLeft] = corners, minFactor = Math.min(Math.abs(w) / (upperLeft.x + upperRight.x), Math.abs(h) / (upperRight.y + lowerRight.y), Math.abs(w) / (lowerRight.x + lowerLeft.x), Math.abs(h) / (upperLeft.y + lowerLeft.y));
                if (minFactor <= 1) for (let radii of corners)radii.x *= minFactor, radii.y *= minFactor;
            }(corners), w < 0 && h < 0 ? (this.moveTo(x - upperLeft.x, y), this.ellipse(x + w + upperRight.x, y - upperRight.y, upperRight.x, upperRight.y, 0, -(1.5 * Math.PI), -Math.PI), this.ellipse(x + w + lowerRight.x, y + h + lowerRight.y, lowerRight.x, lowerRight.y, 0, -Math.PI, -Math.PI / 2), this.ellipse(x - lowerLeft.x, y + h + lowerLeft.y, lowerLeft.x, lowerLeft.y, 0, -Math.PI / 2, 0), this.ellipse(x - upperLeft.x, y - upperLeft.y, upperLeft.x, upperLeft.y, 0, 0, -Math.PI / 2)) : w < 0 ? (this.moveTo(x - upperLeft.x, y), this.ellipse(x + w + upperRight.x, y + upperRight.y, upperRight.x, upperRight.y, 0, -Math.PI / 2, -Math.PI, !0), this.ellipse(x + w + lowerRight.x, y + h - lowerRight.y, lowerRight.x, lowerRight.y, 0, -Math.PI, -(1.5 * Math.PI), !0), this.ellipse(x - lowerLeft.x, y + h - lowerLeft.y, lowerLeft.x, lowerLeft.y, 0, Math.PI / 2, 0, !0), this.ellipse(x - upperLeft.x, y + upperLeft.y, upperLeft.x, upperLeft.y, 0, 0, -Math.PI / 2, !0)) : h < 0 ? (this.moveTo(x + upperLeft.x, y), this.ellipse(x + w - upperRight.x, y - upperRight.y, upperRight.x, upperRight.y, 0, Math.PI / 2, 0, !0), this.ellipse(x + w - lowerRight.x, y + h + lowerRight.y, lowerRight.x, lowerRight.y, 0, 0, -Math.PI / 2, !0), this.ellipse(x + lowerLeft.x, y + h + lowerLeft.y, lowerLeft.x, lowerLeft.y, 0, -Math.PI / 2, -Math.PI, !0), this.ellipse(x + upperLeft.x, y - upperLeft.y, upperLeft.x, upperLeft.y, 0, -Math.PI, -(1.5 * Math.PI), !0)) : (this.moveTo(x + upperLeft.x, y), this.ellipse(x + w - upperRight.x, y + upperRight.y, upperRight.x, upperRight.y, 0, -Math.PI / 2, 0), this.ellipse(x + w - lowerRight.x, y + h - lowerRight.y, lowerRight.x, lowerRight.y, 0, 0, Math.PI / 2), this.ellipse(x + lowerLeft.x, y + h - lowerLeft.y, lowerLeft.x, lowerLeft.y, 0, Math.PI / 2, Math.PI), this.ellipse(x + upperLeft.x, y + upperLeft.y, upperLeft.x, upperLeft.y, 0, Math.PI, 1.5 * Math.PI)), this.closePath(), this.moveTo(x, y);
        }
    }
    function getErrorMessageHeader(instance) {
        var _globalThis, _globalThis1;
        return "Failed to execute 'roundRect' on '" + (Object(instance) === instance && instance instanceof Path2D ? "Path2D" : instance instanceof (null == (_globalThis = globalThis) ? void 0 : _globalThis.CanvasRenderingContext2D) ? "CanvasRenderingContext2D" : instance instanceof (null == (_globalThis1 = globalThis) ? void 0 : _globalThis1.OffscreenCanvasRenderingContext2D) ? "OffscreenCanvasRenderingContext2D" : (null == instance ? void 0 : instance.constructor.name) || instance) + "':";
    }
    window.addEventListener("native.showkeyboard", ()=>{
        let active = document.activeElement;
        active && (active instanceof HTMLInputElement || active instanceof HTMLTextAreaElement) && setTimeout(()=>{
            active.blur(), active.focus();
        }, 200);
    }), polyfill(CanvasRenderingContext2D.prototype, "roundRect", roundRect), polyfill(Path2D.prototype, "roundRect", roundRect);
}
export { };
