function shouldNotBeInvokedBeforeMount() {
    throw Error("foxact: the stablized handler cannot be invoked before the component has mounted.");
}
import { useCallback, useInsertionEffect, useRef } from "react";
export function useEventHandler(callback) {
    let latestRef = useRef(shouldNotBeInvokedBeforeMount), lockRef = useRef(!1);
    return useInsertionEffect(()=>{
        latestRef.current = callback;
    }, [
        callback
    ]), useCallback(function() {
        for(var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++)args[_key] = arguments[_key];
        if (lockRef.current) return null;
        let ret = (0, latestRef.current)(...args);
        return ret instanceof Promise && (lockRef.current = !0, ret.finally(()=>lockRef.current = !1)), ret;
    }, []);
}
