import { useSyncExternalStore } from "react";
import { useTruthyEffect } from "./useTruthyEffect";
let useVisibilityChangeSubscribe = (callback)=>(document.addEventListener("visibilitychange", callback), ()=>{
        document.removeEventListener("visibilitychange", callback);
    }), getVisibilityChangeSnapshot = ()=>document.visibilityState, getVisibilityChangeServerSnapshot = ()=>"visible";
export function useVisibilityChange() {
    return "visible" === useSyncExternalStore(useVisibilityChangeSubscribe, getVisibilityChangeSnapshot, getVisibilityChangeServerSnapshot);
}
export function useVisibleEffect(handler) {
    useTruthyEffect(useVisibilityChange(), ()=>handler());
}
export function useInvisibleEffect(handler) {
    useTruthyEffect(!useVisibilityChange(), ()=>handler());
}
